
import './Leaderboard.css';
import React, {useEffect, useMemo, useState} from 'react';

import Table from "../components/Table";
import SeasonPicker from "../components/SeasonPicker";
import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import url from "../configUtil"

function Leaderboard() {
    const [statData, setStatData] = useState([])
    const [seasonData, setSeasonData] = useState([])
    const [curSeason, setSeason] = useState(0)
    const navigate = useNavigate();
    useEffect( () => {
        async function setUpSeasonList() {
            const seasonsRaw = await fetch(url + '/seasonlist')
            const seasons = await seasonsRaw.json()
            setSeasonData(seasons)
            setSeason(seasons[seasons.length-1])
        }
        setUpSeasonList()
    }, []);

    useEffect( () => {
        async function setUpData() {
            if (curSeason === 0) return;
            const statsRaw = await fetch (url + '/stats/' + curSeason)
            const stats = await statsRaw.json()
            setStatData(stats)
        }
        setUpData()
    }, [curSeason]);


    const columns = useMemo(
        () => [
            // First group columns
            {
                Header: "",
                accessor: "UUID",
                Cell: ({cell: {value}}) => (

                    <img className={"hover"} onClick={() => {
                         navigate("/profile/" +  curSeason + "/" + value)
                    }
                    }
                        src={"https://crafatar.com/avatars/" + value}
                        width={60}
                    />
                )
            },
            {
                Header: "Position",
                accessor: "Position",
            },
            {
                Header: "Name",
                accessor: "Name"
            },
            {
                Header: 'Rank Points',
                accessor: 'RP',
            },
            {
                Header: "Played",
                accessor: "Played"
            },
            {
                Header: "Wins",
                accessor: "Wins"
            },
            {
                Header: "Draws",
                accessor: "Draws"
            },
            {
                Header: "Losses",
                accessor: "Losses"
            },
            {
                Header: "Winrate (%)",
                accessor: "Winrate",
            },
            {
                Header: "Goals",
                accessor: "Goals",
            },
            {
                Header: "Assists",
                accessor: "Assists",
            },
            {
                Header: "Cleansheets",
                accessor: "Totalcleansheets",
            },
            {
                Header: "Rank",
                accessor: "Prefix",
            },
            {
              Header: 'MMR',
              accessor: 'MMR',
            },

            {
              Header: 'Passes',
              accessor: 'Passes',
            },

            {
              Header: 'Turnovers',
              accessor: 'Turnovers',
            },

            {
              Header: 'Shots',
              accessor: 'Shots',
            },
            {
                Header: 'Peak MMR',
                accessor: 'PeakMMR',
            },



        ],
        [curSeason]
    )

    return (
        <div className="leaderboard-page">

            <div className="users">
                {seasonData.map((user) => (
                    <div key={user.UUID} className="user">{user}</div>
                ))}
            </div>

            <div className="table-container">
                <Header/>
                <SeasonPicker seasonData={seasonData} setSeason={setSeason}/>
                <Table columns={columns} data={statData}/>
            </div>
        </div>
    );


}

/*
*
*  */

export default Leaderboard;
