import {useEffect, useState} from "react";
import MatchResult from "../components/MatchResult";
import SeasonPicker from "../components/SeasonPicker";
import MatchList from "../components/MatchList";
import "./MatchPage.css"
import Header from "../components/Header";
import url from "../configUtil"

const MatchPage = () => {
    const [matchData, setMatchData] = useState(null)
    const [seasonData, setSeasonData] = useState([])
    const [curSeason, setSeason] = useState(0)
    const [page, setPage] = useState(0);
    const [isMore, setIsMore] = useState(true)

    useEffect( () => {
        async function setUpSeasonList() {
            const seasonsRaw = await fetch(url + '/seasonlist')
            const seasons = await seasonsRaw.json()
            await setSeasonData(seasons)
            await setSeason(seasons[seasons.length-1])
        }
        setUpSeasonList()
    }, []);

    useEffect( () => {
        async function setUpMatches() {

                if (curSeason === 0) return;
                const matchesRaw = await fetch (url + '/matches/' + curSeason + "?page=" + page)
                const matches = await matchesRaw.json()
                setIsMore(matches.isMore)
                setMatchData(matches.data)
        }
        setUpMatches()
    }, [curSeason, page]);

    if (matchData === null) {
        return (
            <div>
                <Header/>
                <SeasonPicker seasonData={seasonData} setSeason={setSeason} setPage={setPage}/>
                Loading matches
            </div>
        )
    }

    if (matchData.length === 0) {
        return (
            <div>
                <Header/>
                <SeasonPicker seasonData={seasonData} setSeason={setSeason}  setPage={setPage}/>
                No matches recorded for this season!
            </div>
        )
    }

    return (
        <div className={"page"}>
            <Header/>
            <SeasonPicker seasonData={seasonData} setSeason={setSeason}  setPage={setPage}/>
            <div className={"matchListContainer"}>
                <MatchList matchData={matchData} isMore={isMore} setPage={setPage} page={page}/>
            </div>
        </div>
    )
}

export default MatchPage
