import MatchResult from "./MatchResult";
import "./MatchList.css"

const MatchList = ({matchData, page, setPage, isMore, playerID}) => {

    return (
        <div className={"matchResultContainer"}>
            <div className={"pageCont"}>
                <button onClick={() => setPage(page - 1)} disabled={page === 0}>Back</button>
                <span> {page + 1}</span>
                <button onClick={() => setPage(page + 1)} disabled={!isMore}>Next</button>
            </div>
            {matchData.map(match => {
                return(
                        <MatchResult {...match} playerID={playerID}/>
                )
            })}
        </div>
    )

}

export default MatchList
