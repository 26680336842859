import "./SimpleStats.css"
import React, {useState} from "react";
import MatchResult from "./MatchResult";

const SimpleStats = ({statList}) => {


    return (
        <div class ={'outerContainerSimple'}>
                {statList.map(stat => {
                    return (
                        <div className={'statRowContainer'}>
                        <div className={"statName"}>{stat.name}</div> <div className={"statValue"}>{stat.value}</div>
                        </div>
                    )
                })}
        </div>

    )
}

export default SimpleStats
