// Table.js

import './Table.css';
import React, {useState} from "react";
import {useFilters, useSortBy, useTable} from "react-table";

export default function Table({columns, data}) {
    // Use the useTable Hook to send the columns and data to build the table
    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
        setFilter
    } = useTable(
        {
            columns,
            data
        },
        useFilters,
        useSortBy,
    );

    const [filterInput, setFilterInput] = useState("");

    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setFilter("Name", value)
        setFilterInput(value);
    };

    /*
      Render the UI for your table
      - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
    */
    return (
        <div>
            <input
                className = 'filter'
                value={filterInput}
                onChange={handleFilterChange}
                placeholder={"Search name"}
            />
            <i> Pro tip: Click player avatar to get their match history</i>
        <table className = 'table' {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                    {column.isSorted
                        ? column.isSortedDesc
                            ? <img className ="up-arrow"
                                src={"https://upload.wikimedia.org/wikipedia/commons/3/3f/Sort_up_small.svg"}
                                width={60}
                            />
                            : <img className ="down-arrow"
                                src={"https://upload.wikimedia.org/wikipedia/commons/3/3f/Sort_up_small.svg"}
                                width={60}
                            />
                        : ''}
                  </span>

                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                        })}
                    </tr>
                );
            })}
            </tbody>
        </table>
        </div>
    );
}
