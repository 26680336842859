import React from "react";
import "./SeasonPicker.css"

const SeasonPicker = ({curSeason,seasonData, setSeason, setPage}) => {



    const numbersList =  seasonData.map((season, index) => {
        if (index === seasonData.length-1 && curSeason == null) {
            return <option key={index} value={season} selected>{season}</option>
        }
        if (curSeason != null && curSeason == season) {
            return <option key={index} value={season} selected>{season}</option>
        }
        return <option key={index} value={season}>{season}</option>

    })

    return (
        <div className='season-select-container'>
            <select id="season-select" onChange={(e) => {
                setSeason(e.target.value)
                if (setPage !== null) {
                    setPage(0)
                }
            }
            }>
                {numbersList}
            </select>
            <span className="focus"/>
        </div>
    )

}

export default SeasonPicker
