import "./MatchResult.css"
import dateString from "../dateString";
import {useState} from "react";
import {isRouteErrorResponse, Link} from "react-router-dom";

const MatchResult = ({UUID,bluePasses, bluePossession, blueScore, bluePlayers, blueScorers,
                     blueShots, blueTurnovers, blueAverageMMR, redPasses, redPossession, redScore, redPlayers, redScorers,
                     redShots, redTurnovers, redAverageMMR, season, timestamp, playerID}) => {
    const isDraw = redScore === blueScore
    const redWon = redScore > blueScore
    let playerRedWon = redScore > blueScore

    const [showMore, setShowMore] = useState(false)
    if (playerID) {
        let isPlayerRedTeam = (redPlayers.filter(p => p.id === playerID)).length > 0
        if (isPlayerRedTeam) {
            playerRedWon = blueScore > redScore
        }
    }

     let t = timestamp.split(/[- : T ]/);
     let d = new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5].substring(0,2)));

    function PlayersList({players, playerID, isEnd}) {
        let gainClass = "gainWon"
        let isEndClass = isEnd ? "playerEnd" : "player"
        if (playerID) {

            return players.map(p => {
                if (p.gain < 0)  {
                    gainClass = "gainLost"
                }
                if (p.id === playerID) {
                    return <a className={"bold"} href={`/profile/${season}/${p.id}`}>
                        <div className={isEndClass}>
                            {p.rp !== "" ? p.rank + " " + p.rp + " RP" : ""}
                            <div className={"nameGain"}>
                                {p.name} <span className={gainClass}>{Math.round(p.gain)}</span>
                            </div>
                        </div>
                    </a>
                } else {
                    return <a href={`/profile/${season}/${p.id}`}>
                        <div className={isEndClass}>
                            {p.rp !== "" ? p.rank + " " + p.rp + " RP" : ""}
                            <div className={"nameGain"}>
                                {p.name}  <span className={gainClass}>{Math.round(p.gain)}</span>
                            </div>
                        </div>
                    </a>
                }
            })
        } else {
            return players.map(p => {
                if (p.gain < 0)  {
                    gainClass = "gainLost"
                }
                return <a href={`/profile/${season}/${p.id}`}>
                    <div className={isEndClass}>
                        {p.rp !== "" ? p.rank + " " + p.rp + " RP" : ""}
                        <div className={"nameGain"}>
                            {p.name} <span className={gainClass}>{Math.round(p.gain)}</span>
                        </div>
                    </div>
                    </a>
            });
        }
    }


    return (
        <div key={UUID} className={"mainStats"} >
            <div className={isDraw ? "drawMatchResult" : playerRedWon ? "redMatchResult" : "blueMatchResult"}>
                <div className={"bluePlayers " + (isDraw ? "drawTeam" : (redWon ? "losingTeam" : "winningTeam"))}>
                    <PlayersList players={bluePlayers} playerID={playerID} isEnd={false}/>
                </div>
                <div className={"scoreContainer"}>
                    <div className={"averagemmr"}>{Math.round(blueAverageMMR)} - {Math.round(redAverageMMR)}</div>
                        <div className={"score"}>{blueScore} - {redScore}</div>
                        <div className={"time"}>{dateString(d)}</div>
                </div>
                <div className={"redPlayers " + (isDraw ? "drawTeam" : (redWon ? "winningTeam" : "losingTeam"))}>
                    <PlayersList players={redPlayers} playerID={playerID} isEnd={true}/>
                </div>
            </div>
            <div className={isDraw ? "drawShowMoreButton" : playerRedWon ? "redShowMoreButton" : "blueShowMoreButton"} onClick={() => setShowMore(!showMore)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"  fill={isDraw ? "#6a6f7b" : playerRedWon ? "#E84056" : "#5283E5"} >
                    <path d={!showMore ? "M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z" :
                        "m12 6.586-8.707 8.707 1.414 1.414L12 9.414l7.293 7.293 1.414-1.414L12 6.586z"}/>
                </svg>
            </div>
            {showMore &&
                <div className={isDraw ? "drawMore" : playerRedWon ? "redMore" : "blueMore"}>
                    <div className={"blueStatVals"}>
                        <div>
                            {bluePasses}
                        </div>
                        <div>
                            {blueShots}
                        </div>
                        <div>
                            {bluePossession}
                        </div>
                        <div>
                            {blueTurnovers}
                        </div>
                        <div>
                            {blueScorers.map(scorer =>

                                    <div>{scorer.name} - {scorer.goalScored}</div>
                            )}
                        </div>
                    </div>

                    <div className={"statName"}>
                        <div>
                            Passes
                        </div>
                        <div>
                            Shots
                        </div>
                        <div>
                            Possession
                        </div>
                        <div>
                            Turnovers
                        </div>
                        <div>
                            Scorers
                        </div>
                    </div>

                    <div className={"redStatVals"}>
                        <div>
                            {redPasses}
                        </div>
                        <div>
                            {redShots}
                        </div>
                        <div>
                            {redPossession}
                        </div>
                        <div>
                            {redTurnovers}
                        </div>
                        <div>
                            {redScorers.map(scorer =>

                                <div>{scorer.name} - {scorer.goalScored}</div>
                            )}
                        </div>
                    </div>

                </div>
            }
        </div>
    )

}

export default MatchResult
