import "./MainProfile.css"
import React, {useState} from "react";

function PromoForm({promoForm}) {
    let promoGames = 5
    const promoObjects = []
    for (let i = 0; i < promoForm.length; i++) {
        console.log("current char is",  promoForm[i])
        if (promoForm[i] === "0") {
            promoObjects.push("loss")
        }
        if (promoForm[i] === "1") {
            promoObjects.push("draw")
        }
        if (promoForm[i] === "2") {
            promoObjects.push("win")
        }
    }

    console.log(promoObjects)

    for (let i = promoForm.length; i < promoGames; i++) {
        promoObjects.push("unplayed")
    }

    return (
        <>
    {promoObjects.map(game => {
        console.log("game is", game)
        return (
            <div className={game}>⬤</div>
        )
    })}
        </>
    )
}

const MainProfile = ({name, mmr, peakmmr, wins, draws, losses, rank, rp, promoForm, isPromo, uuid, peakrank}) => {

    const [imageShown, setImageShown] = useState(false)
    let winRate = Number(wins) + Number(losses) === 0 ? 0 : Math.round(Number(wins) / (Number(wins) + Number(losses))*100)

    return (
        <div class ={'outerContainer'}>
        <div class={'leftContainer'}>
            <img className={"image"}
                 style={!imageShown ? {} : {display: 'none'}}
                 src={"/steve100.png"}
                 width={100}

            />

            <img className={"image"}
                 style={imageShown ? {} : {display: 'none'}}
                 src={"https://crafatar.com/avatars/" + uuid + "/?size=100.png"}
                 width={100}
                 onLoad={() => setImageShown(true)}
            />

            <div className={"peakRankContainer"}>
                <div className={"peakRankValue"}>&nbsp;{peakrank}</div>
            </div>
            <div class={'prefix'}>{rank}</div>
            <div className={'rp'}>{rp} RP</div>
            <div className={'promoContainer'}>
            {isPromo===1 &&
                <>
                <PromoForm promoForm={promoForm}></PromoForm>
                </>
            }
            </div>
        </div>
        <div class = {'rightContainer'}>
            <div className={"name"}>{name}</div>
            <div className={"mmrContainer"}>
                <div className={"mmrTag"}>MMR</div>
                <div className={"mmr"}>{Math.round(mmr)}</div>
            </div>
            <div className={"peakMmrContainer"}>
                <div className={"peakMmrTag"}>Peak</div>
                <div className={"peakmmr"}>{Math.round(peakmmr)}</div>
            </div>
            <div className={"recordWinContainer"}>
            <div className={"recordContainer"}>
                <div className={"record"}>{wins}W {draws}D {losses}L</div>
            </div>
            <div className={"winRateContainer"}>
                <div className={"winRateTag"}>Win Rate:</div>
                <div className={"winRateValue"}>&nbsp;{winRate}%</div>
            </div>
            </div>
        </div>
        </div>

    )
}

export default MainProfile
