import './Header.css';
import React from 'react';
import {
    Link
} from "react-router-dom";

class Header extends React.Component {

    constructor(props) {
        super(props)
    }

    getStats() {
        fetch('/stats')
            .then(response => response.json())
            .then(stats => this.setState({statData: stats}))

    }


    render() {
        return (
            <div className="header">
                <div className="logo">
                    <p>FBC</p>
                </div>
                {/*<div className="search">*/}
                {/*    <form>*/}
                {/*        <input type='text' id="player-search" name="hi " placeholder="Search for player..." maxLength="16"></input>*/}
                {/*    </form>*/}
                {/*</div>*/}
                <div className="pages">
                    <Link to = "/leaderboard" className="text-link">
                    <p className="leaderboard-text">Leaderboard</p>
                    </Link>
                    <Link to = "/matches" className="text-link">
                    <p className="matches-text">Matches</p>
                    </Link>
                </div>

            </div>
        );
    }
}

export default Header;
