import './App.css';
import Leaderboard from './pages/Leaderboard';
import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import Header from "./components/Header";
import Profile from "./components/Profile";
import MatchPage from "./pages/MatchPage";



class App extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Router>
                {/*<div className ="header-div">*/}
                {/*    <Header/>*/}
                {/*</div>*/}
                <Routes>
                    <Route exact path="/leaderboard" element = {<Leaderboard/>}/>
                    <Route exact path="/matches" element = {<MatchPage/>}/>
                    <Route path="/profile/:season/:uuid" element = {<Profile/>}/>
                    <Route path="*" element = {<Leaderboard/>}/>

                </Routes>
            </Router>

        );
    }
}

export default App;
